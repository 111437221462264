<template>
    <v-dialog
    :value="dialog"
    fullscreen
    transition="scroll-x-reverse-transition"
    >
        <v-card class="elevation-0 pb-16">
            <v-toolbar
                class="elevation-0"
                hide-on-scroll
                >
                <v-btn
                    icon
                    @click="back()"
                    large
                >
                    <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-title class="my-6 font-weight-black text-break secondary--text mx-md-auto" style="font-size: 35px; line-height: 45px; max-width: 1024px;">Términos y condiciones</v-card-title>
            <v-divider class="mx-4"></v-divider>

            <v-card class="elevation-0 mx-4 mx-md-auto" v-for="item in terms_and_conditions" :key="'tac_'+item.id" style="max-width: 1024px;">
                <v-card-title v-if="item.title" class="mt-4 font-weight-black text-break secondary--text"><h3>{{ item.id }}.- {{ item.title }}</h3></v-card-title>
                <v-divider v-if="item.title" class="mx-4"></v-divider>
                <v-card-text class="text-break" v-for="text in item.content" :key="text" style="font-size: 18px;" v-html="text">
                </v-card-text>
            </v-card>
        </v-card>

    </v-dialog>
</template>

<script>
export default {
  name: 'TermsAndConditionsComponent',
  props: ['dialog'],
  data () {
    return {
        terms_and_conditions: [           
            {
                id: 0,
                content: [
                    'Al acceder a nuestro sitio web, usted acepta los términos y condiciones de servicio establecidos a continuación. Lea detenidamente estos términos antes de utilizar nuestros servicios.',
                ]
            },
            
            {
                id: 1,
                title: 'Definiciones',
                content: [
                    'En estos términos y condiciones, los siguientes términos tendrán los siguientes significados:',
                    '<b>Página web</b>: se refiere a la página de subastas de reservados de discotecas.',
                    '<b>Usuario</b>: se refiere a cualquier persona que utilice o visite la página web.',
                    '<b>Subasta</b>: se refiere al proceso de oferta y venta de un reservado de discoteca en la página web.',
                    '<b>Reservado de discoteca</b>: se refiere al espacio reservado en una discoteca para un grupo de personas.'
                ]
            },
            {
                id: 2,
                title: 'Servicios',
                content: [
                    'La página web proporciona un servicio de subastas de reservados de discotecas. Los usuarios pueden pujar por un reservado de discoteca y el usuario con la oferta más alta al final de la subasta gana el derecho a reservar el espacio.',
                    'La página web no es responsable de la organización o provisión del reservado de discoteca. Es responsabilidad del ganador de la subasta contactar con la discoteca y organizar la reserva. La página web no es responsable de cualquier problema o disputa que pueda surgir entre el ganador de la subasta y la discoteca.'
                ]
            },
            {
                id: 3,
                title: 'Registro',
                content: [
                    'Para utilizar los servicios de la página web, los usuarios deben registrarse y crear una cuenta. La información de la cuenta debe ser precisa y completa. Los usuarios son responsables de mantener la seguridad de su cuenta y contraseña.'
                ]
            },
            {
                id: 4,
                title: 'Subastas',
                content: [
                    'Las subastas están abiertas a todos los usuarios registrados. Las ofertas deben cumplir con el precio mínimo establecido para cada reservado de discoteca. Los usuarios pueden realizar varias ofertas durante la subasta.',
                    'La subasta finalizará a la hora y fecha establecidas en la página web. El usuario con la oferta más alta al final de la subasta será el ganador.',
                    'Los usuarios están obligados a pagar el precio de la oferta ganadora. El pago debe ser realizado dentro de un plazo de 48 horas después de la finalización de la subasta. El no pago resultará en la cancelación de la reserva y la exclusión del usuario de futuras subastas.'
                ]
            },
            {
                id: 5,
                title: 'Cancelaciones y reembolsos',
                content: [
                    'Los usuarios pueden cancelar una reserva dentro de un plazo de 24 horas después de la finalización de la subasta y recibirán un reembolso completo. Después de ese plazo, no se realizarán reembolsos.',
                    'En caso de que la discoteca cancele la reserva, la página web no será responsable de reembolsar el precio de la oferta ganadora.'
                ]
            },
            {
                id: 6,
                title: 'Propiedad intelectual',
                content: ['Todo el contenido de la página web, incluyendo texto, gráficos, logotipos, imágenes y software, es propiedad de la página web o de sus proveedores de contenido. Los usuarios no pueden copiar, reproducir, distribuir o utilizar de cualquier otro modo el contenido sin el consentimiento previo y por escrito de la página web.']
            },
            {
                id: 7,
                title: 'Limitación de responsabilidad',
                content: ['La página web no será responsable de ningún daño, pérdida o lesión derivados del uso de los servicios de la página web. La página web no garantiza la calidad, seguridad o idoneidad de los reservados de discoteca ofrecidos en las subastas. Es responsabilidad del ganador de la subasta contactar con la discoteca y confirmar la disponibilidad y condiciones de la reserva.']
            },
            {
                id: 8,
                title: 'Modificaciones',
                content: ['La página web se reserva el derecho de modificar estos términos y condiciones en cualquier momento. Los usuarios serán notificados de las modificaciones y se les solicitará que acepten los nuevos términos antes de continuar utilizando los servicios de la página web.']
            },
            {
                id: 9,
                title: 'Ley aplicable y jurisdicción',
                content: ['Estos términos y condiciones se regirán e interpretarán de acuerdo con las leyes de [país]. Cualquier disputa que surja en relación con estos términos y condiciones se resolverá exclusivamente en los tribunales de [ciudad, país].']
            },
            {
                id: 10,
                content: ['Al utilizar los servicios de la página web, los usuarios aceptan los términos y condiciones de servicio establecidos en este documento.']
            }
        ],
    }
  },
  methods: {
    back() {
      this.$emit('update:dialog', false);
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    /* .v-dialog > .v-card > .v-toolbar {
        position: sticky;
        top: 0;
        z-index: 999;
    } */

</style>
