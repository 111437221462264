<template>
    <v-app-bar 
    app 
    dark 
    clipped-left 
    elevate-on-scroll
    :color="$route.path == '/intro' ? 'primary' : 'translucient-dark'" 
    >
        <v-img
            :src="require('@/assets/large-logo.png')"
            max-height="90"
            max-width="160"
            contain
            @click="$router.push('/intro')"
            style="cursor: pointer;"
        ></v-img>
        <v-spacer></v-spacer>
        <!-- <v-btn color="white" class="mr-3" outlined small @click="$router.push('/login')">Log In</v-btn> -->
        <v-btn color="white" class="mr-0" icon small @click="$emit('toggleDrawer')">
            <v-icon>mdi-menu</v-icon>
        </v-btn>
    </v-app-bar>
    <!-- <v-app-bar 
        app 
        dark 
        clipped-left 
        elevate-on-scroll
        class="translucient-dark" 
        v-else
        >
        <v-row>
            <v-col cols="4"></v-col>
            <v-col cols="4" class="pa-0 py-2">
                <v-img
                    :src="require('@/assets/large-logo.png')"
                    max-height="80"
                    max-width="200"
                    contain
                ></v-img>
            </v-col>
            <v-col cols="4" class="d-flex align-end">
                <v-spacer></v-spacer>
                <v-btn color="white" class="mr-0" icon small @click="$emit('toggleDrawer')">
                    <v-icon>mdi-menu</v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </v-app-bar> -->
</template>

<script>
export default {
  name: 'AppBar',
  data () {
    return {
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    a {
        text-decoration: none;
    }
</style>
