<template>
    <v-app id="app-menu" class="mx-md-auto">
        <v-container class="ma-0 pa-0">
            <v-card class="elevation-0">
                <v-card-title class="mt-16 font-weight-black"><h3>¿Cómo podemos ayudarte?</h3></v-card-title>
                <v-divider class="mx-4"></v-divider>

                <v-list>
                    <!-- <v-subheader>REPORTS</v-subheader> -->
                    <v-list-item-group
                        color="primary"
                        class="mx-2"
                    >
                        <v-list-item 
                        class="py-3" 
                        v-for="item in help_items" 
                        :key="item.title"
                        @click="item.action()"
                        >
                            <v-list-item-avatar>
                                <v-icon>{{ item.icon }}</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                                <span style="width: 700px;" class="d-none d-md-block"></span>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-icon>mdi-chevron-right</v-icon>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-card>
            <ContactComponent :dialog="contact_dialog" @update:dialog="(val) => contact_dialog = val"/>
            <FrequentQuestionsComponent :dialog="frequent_questions_dialog" @update:dialog="(val) => frequent_questions_dialog = val"/>
            <TermsAndConditionsComponent :dialog="terms_and_conditions_dialog" @update:dialog="(val) => terms_and_conditions_dialog = val"/>
            <CookiesPolicyComponent :dialog="cookies_policy_dialog" @update:dialog="(val) => cookies_policy_dialog = val"/>
            <PrivacyPolicyComponent :dialog="privacy_policy_dialog" @update:dialog="(val) => privacy_policy_dialog = val"/>
        </v-container>
    </v-app>
</template>

<script>
// import { mapGetters, mapActions } from 'vuex'
import ContactComponent from '@/components/Home/Help/ContactComponent.vue'
import FrequentQuestionsComponent from '@/components/Home/Help/FrequentQuestionsComponent.vue'
import TermsAndConditionsComponent from '@/components/Home/Help/TermsAndConditionsComponent.vue'
import CookiesPolicyComponent from '@/components/Home/Help/CookiesPolicyComponent.vue'
import PrivacyPolicyComponent from '@/components/Home/Help/PrivacyPolicyComponent.vue'

export default {
  name: 'HelpView',
  components: {
    ContactComponent,
    FrequentQuestionsComponent,
    TermsAndConditionsComponent,
    CookiesPolicyComponent,
    PrivacyPolicyComponent,
  },
  data () {
    return {
        frequent_questions_dialog: false,
        terms_and_conditions_dialog: false,
        cookies_policy_dialog: false,
        privacy_policy_dialog: false,
        contact_dialog: false,
        help_items: [
            {
                title: 'Contacto',
                icon: 'mdi-phone',
                action: () => {
                    this.contact_dialog = true
                }
            },
            {
                title: 'Preguntas frecuentes',
                icon: 'mdi-help-circle',
                action: () => {
                    this.frequent_questions_dialog = true
                }
            },
            {
                title: 'Términos y condiciones',
                icon: 'mdi-file-document',
                action: () => {
                    this.terms_and_conditions_dialog = true
                }
            },
            {
                title: 'Política de privacidad',
                icon: 'mdi-file-document',
                action: () => {
                    this.privacy_policy_dialog = true
                }
            },
            {
                title: 'Política de cookies',
                icon: 'mdi-file-document',
                action: () => {
                    this.cookies_policy_dialog = true
                }
            },
            {
                title: 'Acerca de',
                icon: 'mdi-information',
                action: () => {
                    this.terms_and_conditions_dialog = true
                }
            },
        ],
    }
  },
  computed: {
    // ...mapState(['sites', 'gadgets']),
    // ...mapGetters(['sites', 'gadgets']),
    // ...mapGetters(['prueba_computed']),
  },
  mounted () {
    //   this.getSites()
    //   this.getGadgets()
  },
  methods: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
