/* eslint-disable indent */
<template>
    <v-app id="app-menu" class="mx-md-auto">
        <v-container class="ma-0 pa-0 pb-5 pt-md-16">
            <v-card tile elevation="0">
                <v-img :src="require('@/assets/img/about/about-background.png')" height="600" class="align-end"
                    gradient="to top right, rgba(100,115,201,.44), rgba(25,32,72,.8)">
                    <h1 class="white--text text-center px-5 mb-10" style="font-size: 100px;">Formaciones</h1>
                    <v-card-title class="pa-0">
                        <v-img :src="require('@/assets/img/about/about-shape-1.png')"></v-img>
                    </v-card-title>
                </v-img>
            </v-card>
            <v-row class="ma-0 pa-0">
                <v-col cols="12" class="pt-9 pb-0">
                    <h1 class="text-center">Futuro de la Innovación Empresarial</h1>
                </v-col>
            </v-row>
            <v-card tile elevation="0">
                <v-card-text class="pa-7 text-center text-md-h6">
                    En BidApp, creemos firmemente que el núcleo de cualquier éxito empresarial radica en la innovación
                    continua y el aprendizaje constante. Nuestra misión es liderar el camino hacia el futuro, ofreciendo
                    formaciones avanzadas y personalizadas que preparan a individuos y equipos para enfrentar los
                    desafíos del mañana. Aquí, la innovación no es solo una palabra de moda, es nuestra filosofía de
                    trabajo y la esencia de todas nuestras formaciones.
                </v-card-text>
            </v-card>
            <v-row class="ma-0 pa-0">
                <v-col cols="12" class="pt-6 pb-0">
                    <!-- <h2 class="text-center">¡Trabaja con nosotros para crear la próxima gran experiencia en línea como Desarrollador Web!</h2> -->
                    <h1 class="text-center">Nuestras formaciones</h1>
                    <div class="divider"></div>
                    <div class="mx-auto text-center" style="width: 100%;">
                        <v-btn-toggle v-model="selector" class="mt-5 rounded-lg mx-auto" color="primary">
                            <v-btn text value="current" class="px-6">Actuales</v-btn>
                            <v-btn text value="past" class="px-6">Pasadas</v-btn>
                        </v-btn-toggle>
                    </div>
                </v-col>
            </v-row>
            <div v-if="selector === 'past'">
                <v-card class="pa-4 ma-3 mt-8 d-flex flex-row" v-for="training in pastTrainings" :key="training.title">
                    <h3 class="primary--text pl-2 mt-1">
                        <!-- <v-icon left color="primary">mdi-monitor-cellphone-star</v-icon>
                            Desarrollador web -->
                        <v-icon left color="primary" class="mr-3 mt-n1">{{ training.icon }}</v-icon>
                        {{ training.title }}
                    </h3>
                    <v-btn color="primary" text class="ml-auto" link :href="`./${training.link}`"
                        target="_blank"><b>DESCARGAR</b></v-btn>
                </v-card>
            </div>
            <div v-else>
                <v-card class="pa-4 ma-3 mt-8 d-flex flex-row" v-for="training in currentTrainings"
                    :key="training.title">
                    <h3 class="primary--text pl-2 mt-1">
                        <!-- <v-icon left color="primary">mdi-monitor-cellphone-star</v-icon>
                            Desarrollador web -->
                        <v-icon left color="primary" class="mr-3 mt-n1">{{ training.icon }}</v-icon>
                        {{ training.title }}
                    </h3>
                    <v-btn color="primary" text class="ml-auto" link :href="`./${training.link}`"
                        target="_blank"><b>DESCARGAR</b></v-btn>
                </v-card>
            </div>
        </v-container>
    </v-app>
</template>

<script>
// import { mapActions } from 'vuex'
// import axios from 'axios'
// const path = require('path')

export default {
    name: 'JoinUsView',
    data() {
        return {
            selector: 'current',
            pastTrainings: [
                {
                    title: 'Curso de introducción a Python',
                    icon: 'mdi-language-python',
                    link: 'Curso de Introducción a Python.pdf'
                },
                {
                    title: 'Curso de introducción a la Inteligencia Artificial',
                    icon: 'mdi-brain',
                    link: 'Curso de Introducción a la Inteligencia Artificial.pdf'
                },
                {
                    title: 'Curso de aplicación de herramientas de IA en el trabajo',
                    icon: 'mdi-electron-framework',
                    link: 'Curso de  Aplicación de Herramientas de Inteligencia Artificial en el Trabajo.pdf'
                },
                {
                    title: 'Curso de desarrollo web',
                    icon: 'mdi-monitor-cellphone-star',
                    link: 'Curso de  Desarrollo Web.pdf'
                },
                {
                    title: 'Curso de Docker, Contenedores y Virtualización',
                    icon: 'mdi-docker',
                    link: 'Curso de  Docker, Contenedores y Virtualización.pdf'
                },
                {
                    title: 'Curso Avanzado de Visual Studio Code',
                    icon: 'mdi-microsoft-visual-studio-code',
                    link: 'Curso Avanzado de Visual Studio Code.pdf'
                },
                {
                    title: 'Curso Avanzado de GitHub, Docker y CI-CD',
                    icon: 'mdi-github',
                    link: 'Curso Avanzado de GitHub, Docker y CI-CD.pdf'
                },
                {
                    title: 'Seminario de aplicación de Inteligencia Artificial en la Industria',
                    icon: 'mdi-robot',
                    link: 'Flyer- Seminario sobre Aplicación de Inteligencia Artificial.pdf'
                },
                {
                    title: 'Seminario de aplicación de Ingeniería de Sistemas en la Industria',
                    icon: 'mdi-plane-train',
                    link: 'Flyer- Seminario sobre Aplicación de Ingeniería de Sistemas.pdf'
                },
            ],
            currentTrainings: [
                {
                    title: 'Cursos 2025',
                    icon: 'mdi-book-open-variant',
                    link: 'CURSOS 2025.pdf'
                },
                {
                    title: 'Bootcamps 2025',
                    icon: 'mdi-brain',
                    link: 'BOOTCAMPS 2025.pdf'
                },
            ]
        }
    },
    computed: {

    },
    methods: {
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
